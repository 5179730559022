<template>
  <div class="about">
    <navbar />
    <div class="hero is-primary is-medium is-bold">
      <div class="hero-body">
        <div class="container has-text-centered">
        </div>
      </div>
    </div>
    <div class="container">
      <section class="articles">
        <div class="column is-8 is-offset-2">
          <div class="card article">
            <div class="card-content">
              <div class="media">
                <div class="media-content has-text-centered">
                  <p class="title article-title">Conheça a Coopenaza</p>
                </div>
              </div>
              <div class="content article-body" v-html="about">
              </div>
              <div class="media">
                <div class="media-content has-text-centered">
                  <a class="rede has-text-primary mr-2 facebook" href="https://www.instagram.com/coopenaza_oficial/"><i class="lni lni-facebook" /></a>
                  <a class="rede has-text-primary instagram" href="https://www.instagram.com/coopenaza_oficial/"><i class="lni lni-instagram" /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <Footer />
  </div>
</template>

<script>
import api from '@/api'
import marked from 'marked'
import Navbar from '@/layout/Navbar'
import Footer from '@/layout/Footer'

export default {
  name: 'About',
  components: {
    Navbar,
    Footer
  },
  created () {
    api.get('/configs/1').then(res => {
      this.about = marked(res.data.about)
    })
  },
  data () {
    return {
      about: ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.about {
  .hero {
    .hero-body {
      background-image: url(../assets/images/bg.jpg);
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      height: 500px;
    }
  }
  .container {
    .articles {
      margin: 5rem 0;
      margin-top: -200px;
      .article {
        .media {
          margin-bottom: 1.5rem;
          .media-content {
            margin-top: 3rem;
          }
        }
        .article-body {
          text-align: justify;
          margin: 0 2rem;
        }
      }
    }
  }
}
</style>
